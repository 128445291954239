import { TableVisibility } from '@/components/data-table/DataTable';
import { Log } from '@/models/Log';
import { User } from '@/models/User';
import ErrorHandler from '@/support/ErrorHandler';
import { SettingMap, settingsMap } from '@/support/SettingsMap';
import { dateErrorMessage, formatDate, isValidDate, setFormattedDatePickerValue } from '@/support/String';
import { AxiosError } from 'axios';
import { debounce } from 'lodash';
import { DateTime } from 'luxon';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component<SettingsLog>({})
export default class SettingsLog extends Vue {
  @Prop({ default: () => [] })
  protected visibleSettingKeys!: string[];

  @Prop({ default: () => {} })
  protected filters!: FilterVisibility;

  protected visibleFilters: FilterVisibility = {
    users: true,
    settings: true,
  };

  public $pageTitle = 'Logboek instellingen';

  protected isLoading = true;

  // dates
  protected isEditingFrom = false;

  protected isEditingTo = false;

  protected fromDate = '';

  protected toDate = '';

  // settings
  protected selectedSettings: string[] = [];

  // users
  protected users: User[] = [];

  protected selectedUsers: string[] = [];

  protected userSearch = '';

  protected debouncedSearchUsers: Function = debounce(this.handleSearchUser, 300);

  // datatable
  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  };

  protected selectedTableFilters: any = {};

  public mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    const lastDayOfMonth = DateTime.local().daysInMonth;
    this.fromDate = `${DateTime.local().toFormat('yyyy-MM')}-01`;
    this.toDate = `${DateTime.local().toFormat('yyyy-MM')}-${lastDayOfMonth}`;
    this.visibleFilters = { ...{}, ...this.visibleFilters, ...this.filters };
    this.selectedSettings = this.visibleSettingKeys;

    this.listUsers();
    this.applyFilters();
  }

  protected async listUsers(): Promise<void> {
    this.users = await new User()
      .filter({ search: this.userSearch })
      .limit(this.userSearch.length ? 15 : 5)
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
        return [];
      });
  }

  protected get settings() {
    const settings: SettingMap[] = settingsMap.filter((setting: SettingMap) => this.visibleSettingKeys.includes(setting.key));

    return settings;
  }

  protected applyFilters() {
    this.tableFilters = {
      date: [this.fromDate, this.toDate],
      targets: this.selectedSettings,
      users: this.selectedUsers,
    };

    this.$nextTick(() => {
      this.isLoading = false;
    });
  }

  protected resetSelectedSettings() {
    this.selectedSettings = this.visibleSettingKeys;
  }

  // FROM DATE
  protected fromDateFormatted: string | null = null;

  protected fromDateErrorMessage = '';

  protected formatFromDateFromDatePicker() {
    if (this.fromDate) {
      this.fromDateFormatted = setFormattedDatePickerValue(this.fromDate, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.fromDateErrorMessage = ! isValidDate(this.fromDateFormatted) ? dateErrorMessage : '';
    }
  }

  protected formatFromDateFromTextField(value: string) {
    this.fromDateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.fromDate = setFormattedDatePickerValue(value);
  }

  @Watch('fromDate')
  protected fromDateChanged() {
    this.formatFromDateFromDatePicker();
  }

  @Watch('isEditingFrom')
  protected isEditingFromChanged() {
    if (! this.isEditingFrom) {
      this.formatFromDateFromDatePicker();
    }
  }

  // TO DATE
  protected toDateFormatted: string | null = null;

  protected toDateErrorMessage = '';

  protected formatToDateFromDatePicker() {
    if (this.toDate) {
      this.toDateFormatted = setFormattedDatePickerValue(this.toDate, 'yyyy-LL-dd', 'dd-LL-yyyy');
      this.toDateErrorMessage = ! isValidDate(this.toDateFormatted) ? dateErrorMessage : '';
    }
  }

  protected formatToDateFromTextField(value: string) {
    this.toDateErrorMessage = ! isValidDate(value) ? dateErrorMessage : '';
    this.toDate = setFormattedDatePickerValue(value);
  }

  @Watch('toDate')
  protected toDateChanged() {
    this.formatToDateFromDatePicker();
  }

  @Watch('isEditingTo')
  protected isEditingToChanged() {
    if (! this.isEditingTo) {
      this.formatToDateFromDatePicker();
    }
  }

  // Search
  protected handleSearchUser(query: any) {
    if (! query) {
      return;
    }

    this.listUsers();
  }

  // Getters
  protected get tableFilters(): any {
    return this.selectedTableFilters;
  }

  protected set tableFilters(filters: any) {
    this.selectedTableFilters = filters;
  }

  protected get tableOptions(): Object {
    return {
      model: new Log().filter(this.tableFilters),
      name: {
        singular: 'log',
        plural: 'alle logs',
      },
      headers: [
        {
          text: 'Toelichting',
          value: 'description',
        },
        {
          text: 'Aangemaakt op',
          value: 'created_at',
          sortable: {
            key: 'created_at',
            order: 'ASC',
          },
          transform: (created_at: string) => {
            if (! created_at) {
              return '-';
            }
            return formatDate(created_at);
          },
        },
      ],
    };
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Logboek' },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

export interface FilterVisibility {
  users?: boolean;
  settings?: boolean;
}
