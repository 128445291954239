import SettingsLog from '@/components/SettingsLog/SettingsLog.vue';
import { SettingKey } from '@/models/Setting';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component<PlanningSettingsLog>({
  components: {
    SettingsLog,
  },
})
export default class PlanningSettingsLog extends Vue {
  public mounted() {
    this.emitBreadcrumb();
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          { name: 'Logboek' },
        ],
      });
  }

  protected get visibleSettingKeys() {
    return this.$store.state.Auth.hasRole('admin')
      ? [
        'planning_active_week',
        'planning_schedule_lock_date_users',
        'planning_schedule_lock_date_admins',
        SettingKey.PLANNING_VISIBLE_END_DATE_FOR_TCMG,
        'planning_plan_window_end',
        'max_visit_nivre_deskundige',
        'max_visit_nivre_opnemer',
        'max_visit_10be_deskundige',
        'max_visit_10be_opnemer',
        'max_visit_ced_deskundige',
        'max_visit_ced_opnemer',
        'max_visit_dog_deskundige',

        'planning_emailbox_admins',
        'planning_experts_can_access_availability',
        'planning_experts_notifiable',
      ]
      : [
        SettingKey.PLANNING_VISIBLE_END_DATE_FOR_TCMG,
        'opname_in_progress',
        'planning_schedule_lock_date_admins',
      ];
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}
