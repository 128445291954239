import { SettingKey } from '@/models/Setting';

export const settingsMap: SettingMap[] = [
  // Regulier
  { key: 'planning_emailbox_admins', label: 'E-mailadres Planning' },
  { key: 'planning_experts_can_access_availability', label: 'Zelf beschikbaarheid invullen' },
  { key: 'planning_experts_notifiable', label: 'E-mail deskundigen/zaakbegeleider' },
  { key: 'planning_active_week', label: 'Actieve week' },
  { key: 'planning_schedule_lock_date_users', label: 'Beschikbaarheid blokkeren gebruikers' },
  { key: 'opname_in_progress', label: 'In opname' },
  { key: 'planning_schedule_lock_date_admins', label: 'Beschikbaarheid blokkeren beheerders' },
  { key: SettingKey.PLANNING_VISIBLE_END_DATE_FOR_TCMG, label: 'Vrijgegeven beschikbaar aan IMG' },
  { key: 'planning_plan_window_start', label: 'Planbare periode - start' },
  { key: 'planning_plan_window_end', label: 'Planbare periode - eind' },
  { key: 'max_visit_nivre_deskundige', label: 'Daglimiet planning - NIVRE deskundige' },
  { key: 'max_visit_nivre_opnemer', label: 'Daglimiet planning - NIVRE opnemer' },
  { key: 'max_visit_10be_deskundige', label: 'Daglimiet planning - 10BE deskundige' },
  { key: 'max_visit_10be_opnemer', label: 'Daglimiet planning - 10BE opnemer' },
  { key: 'max_visit_ced_deskundige', label: 'Daglimiet planning - CED deskundige' },
  { key: 'max_visit_ced_opnemer', label: 'Daglimiet planning - CED opnemer' },
  { key: 'max_visit_dog_deskundige', label: 'Daglimiet planning - DOG deskundige' },
  { key: 'max_visit_dog_opnemer', label: 'Daglimiet planning - DOG opnemer' },
  { key: 'max_visit_msr_deskundige', label: 'Daglimiet planning - MSR deskundige' },
  { key: 'max_visit_msr_opnemer', label: 'Daglimiet planning - MSR opnemer' },
  { key: 'max_visit_tcmg_deskundige', label: 'Daglimiet planning - IMG deskundige' },
  { key: 'max_visit_tcmg_opnemer', label: 'Daglimiet planning - IMG opnemer' },

  { key: 'acceptance_is_deploying', label: 'acceptance_is_deploying' },
  { key: 'report_steekproef_interval', label: 'report_steekproef_interval' },
  { key: 'planning_requested_start_date', label: 'planning_requested_start_date' },
  { key: 'planning_requested_end_date', label: 'planning_requested_end_date' },
];

export interface SettingMap {
  key: string;
  label: string;
}
