var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("LoaderCard", {
            attrs: { flat: "", type: "spinner--center", minHeight: "300px" }
          })
        : _vm._e(),
      !_vm.isLoading
        ? [
            _c(
              "v-layout",
              { attrs: { wrap: "", "py-2": "", "align-end": "" } },
              [
                _c(
                  "v-flex",
                  { staticClass: "text-xs-right", attrs: { sm12: "" } },
                  [
                    _c(
                      "MiButton",
                      {
                        attrs: {
                          color: "primary",
                          small: "true",
                          outline: "true"
                        },
                        nativeOn: {
                          click: function($event) {
                            return _vm.applyFilters()
                          }
                        }
                      },
                      [_vm._v(" Pas filters toe ")]
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm2: "" } },
                  [
                    _c(
                      "v-menu",
                      {
                        ref: "dialog",
                        attrs: {
                          "close-on-content-click": false,
                          lazy: "",
                          "nudge-right": "130",
                          "full-width": "",
                          "max-width": "290px",
                          "min-width": "290px"
                        },
                        model: {
                          value: _vm.isEditingFrom,
                          callback: function($$v) {
                            _vm.isEditingFrom = $$v
                          },
                          expression: "isEditingFrom"
                        }
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "input input-date",
                          attrs: {
                            slot: "activator",
                            "hide-details": "",
                            placeholder: "dd-mm-jjjj",
                            label: "Datum vanaf",
                            "error-messages": _vm.fromDateErrorMessage,
                            "prepend-icon": "filter_list"
                          },
                          on: {
                            change: function($event) {
                              return _vm.formatFromDateFromTextField($event)
                            },
                            "click:prepend": function($event) {
                              return _vm.showDateFilterDialog("fromDate")
                            }
                          },
                          slot: "activator",
                          model: {
                            value: _vm.fromDateFormatted,
                            callback: function($$v) {
                              _vm.fromDateFormatted = $$v
                            },
                            expression: "fromDateFormatted"
                          }
                        }),
                        _c("v-date-picker", {
                          ref: "picker",
                          attrs: {
                            locale: "nl-nl",
                            min: "1910-01-01",
                            color: "#837f16",
                            "first-day-of-week": "1"
                          },
                          on: {
                            change: function($event) {
                              _vm.isEditingFrom = false
                            }
                          },
                          model: {
                            value: _vm.fromDate,
                            callback: function($$v) {
                              _vm.fromDate = $$v
                            },
                            expression: "fromDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs12: "", sm2: "" } },
                  [
                    _c(
                      "v-menu",
                      {
                        ref: "dialog",
                        attrs: {
                          "close-on-content-click": false,
                          lazy: "",
                          "nudge-right": "130",
                          "full-width": "",
                          "max-width": "290px",
                          "min-width": "290px"
                        },
                        model: {
                          value: _vm.isEditingTo,
                          callback: function($$v) {
                            _vm.isEditingTo = $$v
                          },
                          expression: "isEditingTo"
                        }
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "input input-date",
                          attrs: {
                            slot: "activator",
                            "hide-details": "",
                            placeholder: "dd-mm-jjjj",
                            label: "Datum tot",
                            "error-messages": _vm.toDateErrorMessage
                          },
                          on: {
                            change: function($event) {
                              return _vm.formatToDateFromTextField($event)
                            }
                          },
                          slot: "activator",
                          model: {
                            value: _vm.toDateFormatted,
                            callback: function($$v) {
                              _vm.toDateFormatted = $$v
                            },
                            expression: "toDateFormatted"
                          }
                        }),
                        _c("v-date-picker", {
                          ref: "picker",
                          attrs: {
                            locale: "nl-nl",
                            min: "1910-01-01",
                            color: "#837f16",
                            "first-day-of-week": "1"
                          },
                          on: {
                            change: function($event) {
                              _vm.isEditingTo = false
                            }
                          },
                          model: {
                            value: _vm.toDate,
                            callback: function($$v) {
                              _vm.toDate = $$v
                            },
                            expression: "toDate"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _vm.visibleFilters.settings
                  ? _c(
                      "v-flex",
                      { attrs: { xs12: "", sm3: "" } },
                      [
                        _c("v-autocomplete", {
                          staticClass: "with-border",
                          attrs: {
                            items: _vm.settings,
                            "item-value": "key",
                            "item-text": "label",
                            "hide-details": "",
                            label: "Instellingen",
                            multiple: "",
                            "append-icon": "playlist_add_check"
                          },
                          on: { "click:append": _vm.resetSelectedSettings },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    _vm.selectedSettings.length > 1 &&
                                    index === 0
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("concatenateString")(
                                                  item.label
                                                )
                                              )
                                            )
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "grey--text caption"
                                            },
                                            [
                                              _vm._v(
                                                "(+" +
                                                  _vm._s(
                                                    _vm.selectedSettings
                                                      .length - 1
                                                  ) +
                                                  ")"
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm.selectedSettings.length === 1
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("concatenateString")(
                                                  item.label
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            1796775394
                          ),
                          model: {
                            value: _vm.selectedSettings,
                            callback: function($$v) {
                              _vm.selectedSettings = $$v
                            },
                            expression: "selectedSettings"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.visibleFilters.users
                  ? _c(
                      "v-flex",
                      { attrs: { xs12: "", sm3: "" } },
                      [
                        _c("v-autocomplete", {
                          staticClass: "with-border",
                          attrs: {
                            items: _vm.users,
                            "item-value": "uuid",
                            "item-text": "name",
                            "hide-details": "",
                            label: "Gebruikers",
                            "search-input": _vm.userSearch,
                            clearable: "",
                            multiple: ""
                          },
                          on: {
                            "update:searchInput": function($event) {
                              _vm.userSearch = $event
                            },
                            "update:search-input": function($event) {
                              _vm.userSearch = $event
                            },
                            keyup: _vm.debouncedSearchUsers
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "selection",
                                fn: function(ref) {
                                  var item = ref.item
                                  var index = ref.index
                                  return [
                                    _vm.selectedUsers.length > 1 && index === 0
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("concatenateString")(
                                                  item.name
                                                )
                                              )
                                            )
                                          ]),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "grey--text caption"
                                            },
                                            [
                                              _vm._v(
                                                "(+" +
                                                  _vm._s(
                                                    _vm.selectedUsers.length - 1
                                                  ) +
                                                  ")"
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm.selectedUsers.length === 1
                                      ? [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("concatenateString")(
                                                  item.name
                                                )
                                              )
                                            )
                                          ])
                                        ]
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            4170980261
                          ),
                          model: {
                            value: _vm.selectedUsers,
                            callback: function($$v) {
                              _vm.selectedUsers = $$v
                            },
                            expression: "selectedUsers"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-layout",
              { attrs: { wrap: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    !_vm.isLoading
                      ? _c("DataTable", {
                          ref: "settingsLogDatatable",
                          attrs: {
                            options: _vm.tableOptions,
                            limit: 10,
                            visibility: _vm.visibility
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }